@import "../../sass/base";
// css modules not supported because of styling stripe elements

.stripe-card-details-container {
  display: flex;
  flex-direction: column;
  .StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: var(--text-primary);
    background-color: var(--background);
    border: none;
    border-bottom: 1px solid var(--avatar-border);
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: var(--background) !important;
  }

  .card-details-title {
    font-size: 1.4rem;
    font-weight: $font-medium;
    margin-bottom: 0.5rem;
  }
}

.applePay {
  .StripeElement {
    box-shadow: 0 0px 3px 1px var(--apple-pay-shadow);
  }
}
