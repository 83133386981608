@import "../../sass/base";

.error {
  font-size: 1.8rem;
  font-weight: $font-medium;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
}
