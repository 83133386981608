@import "../../sass/base";

.headerContainer {
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;

  .avatar {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border-color: var(--avatar-border);
    border-width: 0.2rem;
    border-style: solid;
  }

  .textContainer {
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .titleContainer {
      margin-top: 0.7rem;
      display: flex;
      align-items: center;

      .checkmark {
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
      }

      .title {
        font-size: 3rem;
        font-weight: $font-heavy;
      }
    }
    .subtitle {
      font-size: 1.5rem;
      font-weight: $font-black;
      opacity: 0.6;
      margin-top: -0.2rem;
    }
  }
}

.header {
  display: flex;
  align-items: center;

  .avatar {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
  }

  .checkmark {
    width: 2rem;
    height: 2rem;
  }

  .title {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 2rem;
    font-weight: $font-black;
  }
}
