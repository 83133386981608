html {
  --text-primary: #fbfbfb;
  --background: #000;
  --avatar-border: #48f93b;
  --pricing-border: #fff;
  --pricing-background: #000;
  --button-background: #fff;
  --button-border: transparent;
  --apple-pay-shadow: #e6ebf1;
  --invalid-text: #fa755a;
}

html[data-theme="light"] {
  --text-primary: #000;
  --background: #fff;
  --avatar-border: #48f93b;
  --pricing-border: #fbfbfb;
  --pricing-background: #fbfbfb;
  --button-background: #fff;
  --button-border: #000;
  --apple-pay-shadow: transparent;
}

$font-medium: 500;
$font-black: 900;
$font-heavy: 700;

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}

body {
  font-family: "Avenir";
  color: var(--text-primary);

  background-color: var(--background);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--background) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-primary) !important;
}

a,
a:visited,
a:link {
  text-decoration: none;
  color: var(--text-primary);
}
