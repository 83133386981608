@import "../../sass/base";

.container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.line {
  border-top: 1px solid var(--text-primary);
  border-bottom: none;
  border-left: none;
  border-right: none;
  flex: 1;
  height: 1px;
  opacity: 0.5;
}

.text {
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: $font-heavy;
  opacity: 0.6;
  font-size: 1.4rem;
}
