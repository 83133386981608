//TODO: Separate these out

.titleContainer {
  display: flex;
  align-items: center;
  margin-top: 1.5em;
  height: 5.2rem;
  width: 100%;
  justify-content: center;

  .checkmark {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }

  .title {
    font-size: 3.8rem;
    font-weight: $font-heavy;
    max-width: 100%;
  }

  .titleDynamic {
    text-align: center;
  }
}

.subtitle {
  font-size: 2.1rem;
  font-weight: $font-black;
  opacity: 0.6;
}

.avatar {
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  border-color: var(--avatar-border);
  padding: 0.3rem;
  border-width: 0.3rem;
  border-style: solid;
}

.content {
  font-size: 1.6rem;
  font-weight: $font-heavy;
  padding: 1rem;
  text-align: center;
}

.supportBanner {
  margin-top: 2rem;
  height: 2rem;
}

.supportText {
  font-size: 1.4rem;
  opacity: 0.5;
  font-weight: $font-heavy;
  white-space: pre;
}

.supportLink {
  &,
  &:link,
  &:visited {
    color: var(--text-primary);
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: $font-black;
    padding-bottom: 2px;
  }
}

.supportTextContainer {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

.button {
  height: 5rem;
  border-radius: 1rem;
  border: solid 2px var(--button-border);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.3rem;
  font-weight: $font-black;
  width: 100%;
  background-color: var(--button-background);

  .spacer {
    margin-right: auto;
  }

  .text {
    color: var(--button-text-color);
    padding-left: 1rem;
  }

  .arrow {
    margin-left: auto;
    width: 1rem;
    justify-self: flex-end;
    margin-right: 1rem;
  }
}

.pricingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 35rem;
  min-width: 30rem;

  .pricingItem {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-bottom: 2rem;

    border: 2px solid var(--pricing-border);
    border-radius: 5px;
    background-color: var(--pricing-background);
    .title {
      font-size: 1.6rem;
      font-weight: $font-heavy;
    }

    .limited {
      font-size: 1.6rem;
      font-weight: $font-black;
    }

    .subtitle {
      font-size: 1.6rem;
      opacity: 0.5;
      font-weight: $font-black;
      text-align: center;
    }

    .monthText {
      font-size: 1.8rem;
    }
  }
}

.paymentInfo {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.2rem;
  font-weight: $font-heavy;
  opacity: 0.5;
  text-align: center;
}

.howItWorksHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;

  .info {
    width: 2.2rem;
    height: 2.2rem;
  }

  .text {
    font-size: 1.5rem;
    opacity: 0.5;
    font-weight: $font-heavy;
  }
}

.benefit {
  font-size: 1.5rem;
  font-weight: $font-medium;
}

.benefitList {
  margin-top: 0.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
