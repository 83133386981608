@import "../../sass/base";

.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text {
  font-size: 1.2rem;
  font-weight: $font-heavy;
  text-align: center;
}
