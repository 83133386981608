@import "../../sass/base";

.primaryButton {
  height: 5rem;
  border-radius: 1rem;
  border: solid 2px var(--button-border);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.3rem;
  font-weight: $font-black;
  width: 100%;
  max-width: 300px;
  background-color: var(--button-background);

  .spacer {
    margin-right: auto;
  }

  .text {
    padding-left: 1rem;
  }

  .arrow {
    margin-left: auto;
    width: 1rem;
    justify-self: flex-end;
    margin-right: 1rem;
  }
}
