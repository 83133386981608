.u-margin-bottom {
  margin-bottom: 1rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 3rem !important;
}

.u-margin-top {
  margin-top: 1rem !important;
}

.u-margin-top-medium {
  margin-top: 3rem !important;
}
