@import "../../sass/base";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.text {
  font-size: 1.5rem;
  font-weight: $font-heavy;
}

.secondary {
  font-size: 1.5rem;
  font-weight: $font-heavy;
  opacity: 0.6;
  margin-bottom: 2rem;
}

.thankYou {
  margin-top: 1em;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.title {
  font-size: 2.1rem;
  font-weight: $font-black;
  opacity: 0.6;
  margin-bottom: 3rem;
}
