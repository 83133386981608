@import "../../sass/base";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  font-size: 2.1rem;
  font-weight: $font-black;
  opacity: 0.6;
  margin-bottom: 1.5rem;
}

.spacer {
  margin-bottom: 4rem;
}

.content {
  font-size: 1.6rem;
  font-weight: $font-heavy;
  color: var(--text-primary);
  text-decoration: none;
}

.form {
  margin-top: 4rem;

  .igId {
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: $font-heavy;
    color: var(--text-primary);
    width: 100%;
    border-bottom: 1px solid var(--avatar-border);
    padding: 0.5rem;
  }

  .igId::placeholder {
    color: var(--text-primary);
    opacity: 0.4;
  }
}

.buttonText {
  font-size: 1.5rem;
  font-weight: $font-black;
}

.buttonContainer {
  margin-top: 1.3rem;
  width: 40%;
}

.button {
  height: 3.8rem;
  border-radius: 0.5rem;
}
