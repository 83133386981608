@import "../../sass/base";

.container {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.text {
  font-size: 1.2rem;
  font-weight: $font-heavy;
  opacity: 0.8;

  &:not(:last-child) {
    margin-right: 2rem;
  }
}
