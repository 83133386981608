@import "../../sass/base";

.container {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;

  .input {
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: $font-heavy;
    color: var(--text-primary);
    width: 100%;
    border-bottom: 1px solid var(--avatar-border);
    padding: 0.5rem;
    margin-top: 3rem;
  }

  .input::placeholder {
    color: var(--text-primary);
    opacity: 0.4;
  }

  .button {
    margin-top: 3rem;
    align-self: center;
  }
}



.content {
  margin-top: 1rem;
  font-size: 1.6rem;
  font-weight: $font-heavy;
  opacity: 0.6;
}

.error {
  font-size: 1.6rem;
  font-weight: $font-heavy;
  opacity: 0.8;
  color: red;
}

.sectionTitle {
  margin-top: 3.2rem;
  font-size: 1.6rem;
  font-weight: $font-heavy;
}

.paymentBottomSpacer {
  margin-bottom: 1.5rem;
}

.loading {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);

  .center {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -80px;
  }
}

.paymentLoadingContainer {
  display: flex;
  height: 8rem;
  align-items: center;

  .spinnerContainer {
    margin-right: 1rem;
  }

  .text {
    font-size: 1.6rem;
    font-weight: $font-heavy;
    opacity: 0.6;
    padding-bottom: 1rem;
  }
}
