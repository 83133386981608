.centerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 3rem 2.5rem 3rem;
  max-width: 600px;
  min-width: 320px;
}
